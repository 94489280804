<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">School Setup</h3>
      <ul>
        <li>
          <a href="index.html">Subjects Type</a>
        </li>
        <li>All Subjects Type</li>
      </ul>
    </div>
    <div></div>
    <div> -->
    <DataTableComponent
      v-can="'list-subject-types'"
      :table="table"
    ></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "Subject Type",
        path: "subject_type",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: this.$i18n.t("Notes"),
            value: "en.notes",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Subjects Type"),
        apiEndPoints: {
          list: "subject_types",
          create: "subject_types/store",
          edit: "subject_types/update",
          delete: "subject_types/destroy",
        },
        editedItem: {
          en: {
            name: "",
            notes: "",
          },
          ar: {
            name: "",
          },
        },
        permissions: {
          list: "list-subject-types",
          add: "add-subject-types",
          edit: "edit-subject-types",
          delete: "delete-subject-types",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {},
};
</script>

<style></style>
